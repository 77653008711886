// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---content-pages-404-mdx": () => import("./../content/pages/404.mdx" /* webpackChunkName: "component---content-pages-404-mdx" */),
  "component---content-pages-index-mdx": () => import("./../content/pages/index.mdx" /* webpackChunkName: "component---content-pages-index-mdx" */),
  "component---content-pages-page-1-mdx": () => import("./../content/pages/page-1.mdx" /* webpackChunkName: "component---content-pages-page-1-mdx" */),
  "component---content-pages-page-2-mdx": () => import("./../content/pages/page-2.mdx" /* webpackChunkName: "component---content-pages-page-2-mdx" */),
  "component---content-pages-sub-1-mdx": () => import("./../content/pages/sub-1.mdx" /* webpackChunkName: "component---content-pages-sub-1-mdx" */),
  "component---content-pages-sub-2-mdx": () => import("./../content/pages/sub-2.mdx" /* webpackChunkName: "component---content-pages-sub-2-mdx" */)
}

